// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  if (time.indexOf('01-01-01') > -1) {
    return '-'
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function toRFC3339(date){
  let y = date.getFullYear()
  let m = date.getMonth()+1<10?'0'+(date.getMonth()+1):(date.getMonth()+1)
  let d = date.getDate()<10?'0'+date.getDate():date.getDate()
  let hh = date.getHours()<10?'0'+date.getHours():date.getHours();            
  let mm = date.getMinutes()<10?'0'+date.getMinutes():date.getMinutes()
  let ss = date.getSeconds()<10?'0'+date.getSeconds():date.getSeconds()
  var endDate = y +'-' + m + '-' + d + ' ' + hh + ':' + mm + ':' + ss
  endDate = endDate.replace(/\s+/g, 'T')+'+08:00'
  return endDate
}

export function toTime(rfc3339String) {
  // RFC 3339日期时间格式通常是 "YYYY-MM-DDTHH:mm:ss.sssZ"  
  // JavaScript的Date.parse()可以解析这种格式，但注意它可能不支持毫秒的小数部分  
  // 并且它解析时区"Z"为UTC  
  const date = new Date(rfc3339String);  
  // 检查日期是否有效  
  if (isNaN(date.getTime())) {  
    throw new Error('Invalid RFC 3339 date string');  
  }  
  // 格式化日期为你想要的格式  
  // 例如: "YYYY-MM-DD"  
  const year = date.getUTCFullYear();  
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');  
  const day = String(date.getUTCDate()).padStart(2, '0');  
  const hours = date.getUTCHours().toString().padStart(2, '0');  
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');  
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;  
}